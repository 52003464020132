// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  &.default-header {
    padding: 2.5em 1.25em;
    background-color: var(--themes-colors-primary-background);
    text-align: center;
  }

  &.exp-header {
    position: relative;
    display: flex;
    width: 100%;
    height: 500px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .header-image {
      position: absolute;
      top: 0;
      left: 0;
    }

    .search-wrapper {
      @include full-width-layout-container;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;

      & > * {
        position: relative;
        width: 100%;
      }

      h1 {
        font-size: 64px;
        line-height: normal;
        color: $color-white;
        font-style: normal;
        font-weight: 300;

        @include large-desktop {
          font-size: 96px;
        }

        @include mobile {
          margin: 0;
        }
      }
    }
  }

  &.exp-header.mobile-header {
    height: 100%;

    .search-wrapper {
      padding: 16px;
      padding-top: 96px;
      padding-bottom: 75px;
    }
  }

  .brush-stroke {
    position: absolute;
    z-index: $z-index-brush-stroke;
    height: 31.75em;
    opacity: .14;
    pointer-events: none;
  }

  .brush-stroke-1 {
    top: 0;
    right: 0;
    overflow-x: hidden;
    width: 400px;
    pointer-events: none;

    .brush-stroke-1-content { // fix for safari
      height: 100%;
      margin: -180px 0 0 110px;
      transform: rotate(-225deg);
    }
  }

  .brush-stroke-2 {
    top: -20em;
    left: -20em;
    width: 509px;
    transform: rotate(-230deg);
  }

  .brush-stroke-3 {
    top: 13.75em;
    left: -20em;
    width: 509px;
    transform: rotate(-290deg);
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 1em;
    color: var(--themes-colors-primary-font-color);

    @include not-mobile {
      font-size: 2.125em;
    }

    @include large-desktop {
      font-size: 48px;
      margin-bottom: .5em;
    }
  }

  @include mobile {
    .brush-stroke {
      width: 171px;
      height: 300px;
    }

    .brush-stroke-1 {
      top: -6.875em;
      right: -1.25em;
    }

    .brush-stroke-2 {
      top: -10.625em;
      left: -4em;
      width: 300px;
    }

    .brush-stroke-3 {
      top: 10.625em;
      left: -11.875em;
      width: 300px;
    }
  }
}

