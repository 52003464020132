@import 'src/styles/imports';

.notification-banner {
  position: fixed;
  z-index: calc($z-index-app-banner - 1);
  bottom: 20px;
  left: 50%;
  display: flex;
  width: 95%;
  max-width: 1000px;
  padding: 15px 20px;
  border-radius: var(--themes-buttons-border-radius-banner);
  background-color: $color-dark-navy-bg;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%);

  .notification-content {
    display: flex;
    align-items: center;
    flex-grow: 1;

    p {
      font-size: 14px;
      line-height: 20px;
      padding: 15px;
      margin-right: 35px;
      font-family: var(--themes-fonts-text);
      font-style: normal;
      font-weight: 400;
      letter-spacing: .25px;
      user-select: none;

      strong {
        font-weight: bold;
      }
    }

    @media (max-width: 768px) {
      padding: 15px;
      align-items: flex-start;
      flex-direction: column;

      p {
        padding: 0 0 15px;
        margin-right: 0;
      }
    }
  }

  .notification-title {
    font-size: 20px;
    display: flex;
    width: 100%;
    line-height: 32px;
    padding: 15px 0 0 15px;
    color: $color-warm-black;
    font-family: var(--themes-fonts-heading);
    font-weight: 700;
    align-items: center;
    gap: 10px; 

    .maintenance-icon {
      width: 16px;
      height: 16px;
    }
  }

  .close-button {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 32px;
    border: 0;
    border-radius: 8px;
    margin-right: 10px;
    background-color: $color-warm-black;
    color: $color-white;
    font-family: var(--themes-fonts-text);
    font-weight: 400;
    letter-spacing: .25px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;

    &:hover {
      background-color: $color-grey-8;
    }
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    user-select: none;
  }
}
