@import 'src/styles/imports';

.component {
  width: 100%;
  padding: 1em;
  margin-bottom: 1em;

  .home-block {
    padding-top: 0;
  }

  .header {
    display: flex;
    margin-top: 2em;
    font-family: var(--themes-fonts-heading);
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
      margin-top: 0;
    }

    .left {
      flex: 1;
    }
  
    a {
      padding-right: .2em;
      text-decoration: none;
    }

    .properties {
      font-size: 64px;
      color: $color-grey-18;
      text-align: left;
  
      @media screen and (max-width: 380px) {
        font-size: 36px;
        line-height: 50px;
        font-weight: $font-weight-light;
      }
  
      @media screen and (min-width: 380px) and (max-width: 600px) {
        font-size: 40px;
        font-weight: $font-weight-light;
      }
  
      @media screen and (min-width: 600px) and (max-width: 800px) {
        font-size: 46px;
        font-weight: $font-weight-light;
      }
  
      @media screen and (min-width: 800px) and (max-width: 1000px) {
        font-size: 54px;
        line-height: 88px;
      }
    }

  }

  .location {
    font-size: 72px;
    position: relative;
    padding-left: 30%;
    margin-top: .2em;
    margin-bottom: .5em;
    background: $color-modern-silver-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    color: $color-dark-navy;
    font-weight: 500;
    transition: color .2s ease-in-out;

    @include mobile {
      font-size: 42px;
      line-height: 30px;
      padding-left: 25%;
    }

    @include tablet {
      font-size: 50px;
    }
  }

  .location:hover {
    color: $color-transparent;
    text-decoration: underline;
  }

  .location::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 23%;
    width: 5%;
    height: 5px;
    background-color: $color-black;

    @include mobile {
      left: 18%;
    }
  }

  .mobile-view-map {
    font-size: .9em;
    width: 120px;
    margin: auto;
    font-weight: $font-weight-black;
    text-align: center;
  
    @media (min-width: $breakpoint-tablet) and (max-width: 1300px) {
      font-size: 1.3em;
      width: 150px;
    }
  }
}