@import 'src/styles/imports';

$content-block-max-width: 400px;
$content-spacing-horizontal: 1em;
$image-border-radius: 8px;
$component-breakpoint-desktop-small: 1280px;
$component-breakpoint-tablet-large: 1000px;
$component-breakpoint-tablet-small: 800px;
$component-breakpoint-mobile: 600px;
$why-join-us-button-width: 180px;
$why-join-us-button-height: 64px;

.hero {
  position: relative;
  display: flex;
  overflow: hidden; /* Ensures cropping */
  width: 100%;
  height: 500px;

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .headerContent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 5rem;
      line-height: normal;
      margin-bottom: 40px;
      color: #F3F3F4;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;

      @include mobile {
        font-size: 3rem;
      }
    }

    input {
      padding: 28px !important;
      
    }
  }
}

.heading-search {
  z-index: $z-index-7;
  width: 100vw;

  .custom-button-style {
    font-size: 11vw;
    line-height: 1;
    padding: 0;
    border-bottom: 3px solid $color-grey-12;
    border-radius: 0;
    margin: 0 6vw;
    color: $color-grey-13;

    svg {
      width: 8vw;
      margin-top: 10px;
      margin-right: 2vw;

      path {
        fill: none;
      }
    }
  }
}

.home-sections {
  width: 100%;
  margin-bottom: 1em;

  &.bottom-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &.sold {
    max-width: 100%;
  }

  &.sold-overlay,
  &.agent-search,
  &.content-block {
    max-width: $content-block-max-width;
  }

  &.market-insights {
    display: grid;
    max-width: 100%;
  }

  &.featured-homes {
    max-width: 100%;
  }

  &.buy-guide {
    max-width: 100%;
  }

  .home-block {
    padding: 1.5em $content-spacing-horizontal;
  }

  &.agent-search {
    box-shadow: 0 0 10px 0 $color-black-10;
    border-radius: var(--themes-buttons-border-radius-medium);
    margin: 1.5em $content-spacing-horizontal;
    margin-bottom: 2.5em;

    .home-block {
      display: flex;
      flex-direction: column;
    }

    .row {
      margin-bottom: 0;
      align-items: start;
      flex-direction: column;
    }

    h2 {
      margin-top: 1em;
    }

    .subtext {
      margin-top: 18px;
      margin-bottom: 18px;
    }

    .home-block .agent-image-wrapper {
      position: relative;
      width: 100%;
      height: 200px;
      margin-top: 48px;
    }
  }

  .container {
    width: 100%;
    @include full-width-layout-container(calc($full-width-side-padding - $content-spacing-horizontal));
  }

  &.find-by-keyword-exp {
    display: flex;
    padding: 3em 0;
    margin-bottom: 0;
    background-color: $color-exp-keywords-bg;
    flex-direction: column;

    h2 {
      font-size: 4em;
      line-height: 1.2em;
      color: $color-dark-navy-light;
      font-weight: 500;

      b {
        color: var(--themes-colors-primary);
        font-weight: 800;
      }
    }

    .keywords-list {
      display: grid;
      overflow: scroll;
      padding: 0 2em;
      margin: 0 -2em;
      grid-template-rows: repeat(2, auto);
      grid-auto-columns: max-content;
      grid-auto-flow: column;
      grid-column-gap: .2em;
      scrollbar-width: none; // sass-lint:disable-line no-misspelled-properties
    }

    @include mobile {
      padding-top: 3em;

      h2 {
        font-size: 2.3em;
      }
    }
  }

  &.find-by-keyword .home-block {
    display: flex;
    flex-direction: row;

    .find-by-keyword-heading {
      min-width: 150px;
      margin-right: 4em;

      h2 {
        font-size: 2.1em;
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &:nth-of-type(4) { // component shows in a different order on mobile
    .buyers-guide {
      @include mobile {
        display: none;
      }
    }
  }

  @include mobile {
    .home-block {
      border: 0;
    }

    &:first-of-type {
      .home-block {
        padding-top: 0;
      }
    }

    &:last-of-type {
      .home-block {
        padding-bottom: 0;
        border-bottom: 0;
      }

      .buyers-guide {
        display: block;
        min-height: 9.8em;
        margin-top: 0;
        margin-bottom: 1em;
      }
    }
  }

  .exp-remove-btm-padding {
    padding-bottom: 0 !important; // sass-lint:disable-line no-important
    flex-direction: column;
  }

  &.exp-home {
    .content-block {
      width: calc(50% - 1.5em);
    }

    @include mobile {
      .home-block {
        border: 0;
        border-bottom: 0;
      }
    }

    &.find-by-keyword {
      h2 {
        font-size: 1em;
      }

      .find-by-keyword-heading {
        font-size: 40px;
        min-width: 200px !important;  // sass-lint:disable-line no-important
        margin-right: 2em !important; // sass-lint:disable-line no-important

        @include tablet {
          font-size: 36px;
        }

        @include mobile {
          font-size: 32px;
        }
      }

      @media (max-width: 1000px) {
        .find-by-keyword-heading {
          margin-bottom: 24px;
        }
      }
    }

  }

  @media (max-width: 1000px) {
    .home-sections.sold {
      width: 100%;
      max-width: 100%;
    }

    &.sold .home-block,
    &.featured-homes .home-block {
      width: 100%;
    }

    .home-sections.featured-homes,
    .home-sections.content-block,
    .home-sections.market-insights {
      width: 100%;
      max-width: 100%;
    }

    &.exp-home {
      .home-sections.content-block,
      .home-sections.agent-search {
        width: 100%;
        max-width: 100%;

        .home-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .home-sections.agent-search {

        .home-block {
          flex-direction: row;

          >div {
            width: 100%;
          }

          @include tablet {
            flex-direction: column;
          }

          @include mobile {
            flex-direction: column;
          }
        }

        h2 {
          margin-top: 0;
        }

        .agent-image-wrapper {
          width: 300px;
          margin-top: 0;
          margin-left: 3em;

          @include tablet {
            width: 100%;
            margin-top: 2em;
            margin-left: 0;
          }
          @include mobile {
            width: 100%;
            margin-top: 2em;
            margin-left: 0;
          }
        }
      }
    }
  }

  h2 {
    font-size: 48px;
    color: $color-dark-navy;
    font-family: var(--themes-fonts-heading);
    font-weight: 500;

    &:hover {
      &::after {
        background-color: $color-sand-light;
      }
    }
  }

  .row {
    position: relative;
    display: flex;
    margin-bottom: 2em;
    align-items: center;
    justify-content: space-between;

    .exp-headings {
      font-size: 40px;
      line-height: normal;
      font-weight: 300;
      text-decoration: none;

      .exp-headings-location {
        display: block;
        text-decoration: underline;
        text-decoration-thickness: 2px; // sass-lint:disable-line no-misspelled-properties
        text-underline-offset: 6px; // sass-lint:disable-line no-misspelled-properties
      }

      h2 {
        // Override h2 font-size from 1.625em;
        font-size: 1em;
      }

      @include tablet {
        font-size: 36px;
      }

      @include mobile {
        font-size: 32px;

        .exp-headings-home-sale {
          font-size: 24px;
        }
      }
    }

    a:nth-of-type(2) {
      &:hover {
        text-decoration: none !important; // sass-lint:disable-line no-important
      }
    }

    .label {
      font-size: .75em;
      position: absolute;
      bottom: -1.8em;
    }

    button {
      height: 100%;
      padding: 12px 16px;
    }
  }

  .exp-sold-price-divider {
    // Make sure exp theme spacing is 100px
    // Swiper padding bottom - 16px
    // Home block above padding bottom - 24px
    // Home block below padding bottom - 24px
    // 24 + 24 + 16 = 64px
    width: 100%;
    height: 36px;

    @include tablet {
      height: 16px;
    }

    @include mobile {
      display: none;
    }
  }

  .exp-homes-sale-divider {
    // Make sure exp theme spacing about homes for sale is 120px
    // Home module padding top - 32px
    // Home sales padding top - 24px
    width: 100%;
    height: 4px;

    @include tablet {
      height: 0;
    }
  }
}

.accessibility-wrapper {
  position: fixed;
  z-index: $z-index-chat-widget;
  right: 3em;
  bottom: 3em;
}

.home-modules {
  position: relative;
  display: flex;
  padding-top: 2em;
  padding-bottom: 2em;
  margin: 0 auto;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  @include full-width-layout-container(calc($full-width-side-padding - $content-spacing-horizontal));
  gap: 4.75em;

  &.top {
    padding-bottom: 0;
    margin-bottom: -2em;
  }

  .bottom {
    padding-top: 0;
  }

  @media (max-width: $breakpoint-mobile) {
    &.bottom {
      padding-top: 2em;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .home-sections {
    &.sold,
    &.market-insights,
    &.featured-homes,
    &.agent-search,
    &.find-by-keyword,
    &.content-block {
      width: 100%;
      max-width: 100%;
    }

    &.market-insights .home-block,
    &.available .home-block,
    &.sold .home-block,
    &.agent-search .home-block,
    &.featured-homes .home-block {
      width: 100%;
    }
  }
}

.tablet-button {
  height: 100%;
  padding: 12px 16px;
  margin: 0 auto;

  &.blog-button {
    margin-top: 20px;
  }
}


.testimonialBlock {
  position: relative;
  overflow: hidden;
  width: 100%;
  // height: 588px;
  padding: 80px;
  
  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .contentWrapper {
    position: relative;
    display: flex;
    padding: 10px;
    font-family: Manrope;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: 50px;
    }
    h2 {
      @media (max-width: $component-breakpoint-tablet-large) {
        text-align: center;
      }
    }

    .testimonialWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .testimonial {
        font-size: 16px;
        display: flex;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.10);
        width: 30%;
        min-width: 300px;
        line-height: 28px;
        padding: 32px;
        border-radius: 16px;
        margin: 8px;
        background: #FFF;
        font-style: normal;
        font-weight: 500;
        flex-direction: column;
        justify-content: space-between;

        .description {
          margin-bottom: 16px;
        }

        .agentInfo {
          display: flex;
          padding-top: 10px;
          align-items: center;

          .agentImage {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 16px;
            background: lightcoral;
          }
          .agentName {
            p:first-of-type {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.militaryJoinSection {
  display: flex;
  width: 100%;
  padding: 80px 72px;
  font-family: Manrope;
  justify-content: space-between;

  @media (max-width: $component-breakpoint-tablet-large) {
    padding: 1.5em 1em;
    flex-direction: column;
  }

  .image-container {
    overflow: hidden;
    min-height: 200px;
    flex: 0 0 50%;

    .image {
      width: 100%;
      height: 100%;
      border-radius: $image-border-radius $image-border-radius 125px;
      object-fit: cover;

      @media (max-width: $component-breakpoint-tablet-large) {
        border-top-right-radius: 125px;
        border-bottom-right-radius: $image-border-radius;
      }
    }
  }

  .textContainer {
    width: 45%;

    @media (max-width: $component-breakpoint-tablet-large) {
      width: 100%;
    }

    h2:first-of-type {
      color: var(--e-xp-primary-dark-navy-60, #6D6F7C);
    }
    h2 {
      font-size: 6rem;
      line-height: 1.1em;
      color: $color-dark-navy-light;
      font-family: Manrope;
      font-weight: bold;

      @media (max-width: $component-breakpoint-desktop-small) {
        font-size: 5rem;
      }

      @media (max-width: ($component-breakpoint-tablet-large)) {
        font-size: 6rem;
        line-height: 1.1em;
      }

      @media (max-width: ($component-breakpoint-mobile)) {
        font-size: 2.5rem;
        line-height: 1.2em;
      }
    }
    p {
      line-height: 135%; /* 27px */
      margin: 28px 0;
      font-family: Manrope;
    }

    .join-button {
      font-size: 1.2rem;
      width: $why-join-us-button-width;
      height: $why-join-us-button-height;
      border: 2px solid $color-black-50;
      border-radius: calc($why-join-us-button-width/4);
    }
  }
}