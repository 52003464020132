@import 'src/styles/imports';

.component {
  background-color: $color-sand-very-light;

  .container {
    @include layout-container;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  p {
    margin-right: .7em;
  }

  .link {
    display: flex;
    color: $color-black;
    font-weight: bold;
    text-decoration: underline;
    align-items: center;

    .arrow {
      margin-left: .4em;
      transform: scale(-1);
    }
  }

  .close {
    cursor: pointer;

    g {
      stroke: $color-black;
      stroke-width: 3;
    }
  }
}

